import React, { Component } from 'react'
import estatusimg from 'img/vectores/estatus-corrida.svg'
import { AButton, AInput } from 'shared/components/common'

interface AtravelstatusProps {
  onConfirm?: (ticket: string) => void
}

class Atravelstatus extends Component<AtravelstatusProps> {
  state = {
    input: ''
  }
  constructor(props: AtravelstatusProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.sendTicket = this.sendTicket.bind(this)
  }

  sendTicket() {
    const { onConfirm } = this.props
    let ticketnumber: string
    ticketnumber = this.state.input
    return onConfirm && onConfirm(ticketnumber)
  }

  handleChange(e: any) {
    this.setState({ input: e.target.value })
  }

  render() {
    return (
      <div className="estatus-corrida">
        <h1>
          Estatus de
          <br />
          Corrida <img src={estatusimg} style={{ marginLeft: '20%' }} />
        </h1>
        <br />
        <AInput
          id="numcamion"
          name="numcamion"
          onChange={this.handleChange}
          placeholder="Código de reservación"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', height: 50, border: '1px solid transparent', color: 'white' }}
          uppercase
          required
        />
        <br />
        <br />
        <div className="text-right">
          <AButton onClick={this.sendTicket} variant="pink">
            Buscar
          </AButton>
        </div>
      </div>
    )
  }
}

export default Atravelstatus
