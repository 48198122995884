import { ApiService } from 'core/services'

class StatusRUnService {
  public static instance: StatusRUnService

  constructor() {
    if (StatusRUnService.instance) {
      return StatusRUnService.instance
    }
    StatusRUnService.instance = this
  }

  getStatusTravel(reservation: string) {
    return ApiService.get(`boardingPasses/runState/${reservation}`)
  }
}

const instance = new StatusRUnService()

Object.freeze(instance)

export const StatusRun = instance
